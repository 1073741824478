<div class="surface-section relative mt-5 cursor-pointer" (click)="toggleCheckbox()">
    <span
        class="bg-blue-100 text-blue-600 text-base sm:text-lg w-full sm:w-auto font-medium py-2 px-4 extension-badge border-round-3xl">
        Buy today and save 10€
    </span>
    <div class="p-3 z-2 shadow-2 extension-card-bg flex flex-column sm:flex-row align-items-center justify-content-between"
        style="border-radius: 1rem; ">

        <div class="text-left w-full">
            <div class="flex align-items-center justify-content-between w-full  mt-3 mb-2">
                <div class="text-white font-bold text-lg sm:text-2xl flex align-items-center gap-3">
                    <p-checkbox class="pointer-events-none" name="pizza" [binary]="true" [(ngModel)]="extensionSelected"
                        styleClass="extensionRadio extension-checkbox">
                    </p-checkbox>
                    <span>Extension </span>
                </div>
                <div class="sm:mt-4 md:mt-0 md:mr-0 text-right">

                    <h3 class="m-0 p-0 text-lg sm:text-2xl text-white">+20€ <span class="line-through font-light">+30€</span></h3>
                </div>
            </div>
            <div class="text-white font-medium text-base sm:text-lg flex align-items-center gap-1 mb-2">
                <!-- <i class="pi pi-check text-2xl"></i>  -->
                One Month Extension
            </div>
        </div>

    </div>

    @if (extensionSelected()) {
    <span class="text-white text-base font-medium py-1 px-3 extension-badge-selected border-round-3xl">
        Selected
    </span>
    }


</div>

@if (text()) {
<p-messages severity="info">
    <ng-template pTemplate>

        <div class="flex align-items-center flex-row">
            <i class="pi pi-info-circle text-xl mr-2"></i>
            <div class="ml-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                been the industry’s standard dummy text ever since the 1500s.</div>
        </div>
    </ng-template>
</p-messages>
}