import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, model, ModelSignal, type OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Message } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
    selector: 'app-package-extension-card',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        CheckboxModule,
        RadioButtonModule,
        MessagesModule,
    ],
    templateUrl: './package-extension-card.component.html',
    styleUrl: './package-extension-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageExtensionCardComponent implements OnInit {
    text = input('');
    extensionSelected: ModelSignal<boolean> = model(false);
    messages: Message[] = [];

    ngOnInit(): void { 
        this.messages = [
            { severity: 'info', 
                summary: 'Disclaimer', 
                detail: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.' },
        ];
    }

    toggleCheckbox(): void {
        this.extensionSelected.set(!this.extensionSelected());
    }
}
